import React, { useState } from "react";
import {
  ContentSwitcher,
  Switch as ContentGroup,
  Table,
  TableHeader,
  TableRow,
  TableBody,
  TableCell,
  ClickableTile,
} from "carbon-components-react";
import { Link } from "react-router-dom";
import { ReactComponent as BusinessOwner } from "../../assets/icons/BusinessOwner.svg";

const houses = {
  high_uncertainty: {
    num_rooms: 1,
    crime_rate: 0.00977085,
    education_quality: 0.04564875,
    prediction: 478,
    interval: 19.78,
  },
  low_uncertainty: {
    num_rooms: 4,
    crime_rate: 0.48354565,
    education_quality: 0.48762781,
    prediction: 610,
    interval: 5.67,
  },
};

const commStyles = {
  verbal: {
    title: "Range of prediction interval (verbal)",
    desc: "Easy to read at a glance, but could miss the details of how possible values are distributed in the range",
  },
  density: {
    title: "Probability density plot",
    desc: "Gives detailed information with a visualization about how possible values are distributed in the prediction interval",
  },
  quantile: {
    title: "Quantile dot plot",
    desc: "Shows distribution with a visualization that makes it easier to judge the relative likelihood of where possible values can fall",
  },
};

function CommStyleTile(props) {
  return (
    <ClickableTile
      key={`tile-${props.commStyle}`}
      handleClick={() => {
        props.setCommStyle(props.commStyle);
      }}
      className={`${props.active ? "dark" : ""}`}
      style={{
        width: "18em",
        display: "inline-block",
        marginRight: "1em",
        height: "10em",
      }}
    >
      <div className="presentation-full-tile">
        <div className="presentation-tile-text">
          <div className="presentation-tile-text-title">
            {commStyles[props.commStyle].title}
          </div>
          <div className="presentation-tile-text-description">
            {commStyles[props.commStyle].desc}
          </div>
        </div>
      </div>
    </ClickableTile>
  );
}

export function Decision() {
  let [mode, setMode] = useState("high_uncertainty");
  let [commStyle, setCommStyle] = useState("verbal");
  return (
    <>
      <div className="bx--row">
        <div className="bx--col-lg-2">
          <BusinessOwner width="90px" height="90px" />
        </div>
        <div className="bx--col-lg-14">
          <h3>Use UQ to make decisions</h3>
          <p>
            Ella , the seller wants to know
            <br />
            <span className="question">
              What price should I set based on the AI’s suggestion?
            </span>
          </p>
        </div>
      </div>
      <p>
        Ella uses the AI system to help her set the prices for houses that she
        puts on a real estate marketplace website. Ella understands that
        predictions of a ML model involve uncertainty. So she is glad to see
        that this AI system not only gives a predicted price but also the UQ
        information to tell her where the price can fall with 95% probability.
        She is also glad to know that the UQ information has been evaluated so
        she can confidently rely on it as an AI suggested price range.
      </p>
      <p>
        The UQ information of a predicted house price could be communicated to
        Ella with different levels of precision and in different formats (e.g.,
        verbal, visualization). Try the following and see different ways to
        communicate the uncertainty estimate of a house price prediction, or
        check out our{" "}
        <Link to="/resources/communication">
          Guide for Communicating Uncertainty
        </Link>
        .
      </p>
      <p>First, select a house below to see its model prediction:</p>
      <ContentSwitcher
        style={{ width: "30em", marginBottom: ".5em" }}
        onChange={(obj) => {
          let { name } = obj;
          if (typeof name === "string") setMode(name);
        }}
      >
        <ContentGroup
          name="high_uncertainty"
          text="House A (high uncertainty)"
        />
        <ContentGroup name="low_uncertainty" text="House B (low uncertainty)" />
      </ContentSwitcher>

      <Table style={{ width: "30em", marginBottom: "1em" }}>
        <TableBody>
          <TableRow>
            <TableHeader>Number of rooms</TableHeader>
            <TableCell>{houses[mode]["num_rooms"]}</TableCell>
          </TableRow>
          <TableRow>
            <TableHeader>Crime rate</TableHeader>
            <TableCell>{houses[mode]["crime_rate"].toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableHeader>Education quality</TableHeader>
            <TableCell>
              {houses[mode]["education_quality"].toFixed(2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <p>Then select a UQ communication method below:</p>

      <div style={{ marginBottom: ".5em" }}>
        <CommStyleTile
          commStyle="verbal"
          setCommStyle={setCommStyle}
          active={commStyle === "verbal"}
        />
        <CommStyleTile
          commStyle="density"
          setCommStyle={setCommStyle}
          active={commStyle === "density"}
        />
        <CommStyleTile
          commStyle="quantile"
          setCommStyle={setCommStyle}
          active={commStyle === "quantile"}
        />
      </div>

      <div className="bx--row">
        <div className="bx--col-lg-8">
          <div className="price">
            <h2>Recommended price:</h2>
            <h2 style={{ color: "darkgreen" }}>
              {houses[mode]["prediction"]}K
            </h2>
            {commStyle === "verbal" && (
              <h5>
                margin of error with 95% confidence:{" "}
                <span style={{ color: "darkgreen" }}>
                  +/- {houses[mode]["interval"].toFixed(0)}K
                </span>
                .
              </h5>
            )}
            {commStyle === "density" && (
              <h4>
                The density plot below shows the probability of the right price.
              </h4>
            )}
            {commStyle === "quantile" && (
              <h4>
                The quantile plot below shows the probability of the right
                price.
              </h4>
            )}
          </div>
          {commStyle !== "verbal" && (
            <img
              src={`/imgs/${mode}_${commStyle}.png`}
              alt={`${mode}_${commStyle}`}
            ></img>
          )}
        </div>
        <div className="bx--col-lg-8">
          <div className="sidebox">
            <p>
              Ella understands that while the model prediction output is{" "}
              {houses[mode]["prediction"]}K, the recommended range says the
              price can fall anywhere between{" "}
              {(houses[mode]["prediction"] - houses[mode]["interval"]).toFixed(
                0
              )}
              K and{" "}
              {(houses[mode]["prediction"] + houses[mode]["interval"]).toFixed(
                0
              )}
              K.
            </p>
            <p>
              {mode === "high_uncertainty" && (
                <>
                  This is a somewhat large range. Ella also has to take into
                  consideration what she knows about the housing market:
                  <ul>
                    <li>
                      The buying demand is on the rise in the neighborhood
                    </li>
                    <li>It is common to set the first price slightly higher</li>
                  </ul>
                </>
              )}
              {mode === "low_uncertainty" &&
                "This is a relatively small range. Ella could set the house price around 610K with confidence. "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
