import React from "react";
import { Link } from "carbon-components-react";

import './AppFooter.scss';

export const AppFooter = () => (
    <section className="bx--row footer">
        <div className="bx--col-lg-8">
            <div className="footer-wrapper">
                <p className="section-title">About this site</p><br/><br/>
                <p className="footer-text">
                UQ360 was created by <Link href='https://www.research.ibm.com/' target='_blank'>IBM Research</Link>.
                <br/><br/>
                Additional research sites that advance other aspects of <Link href='https://www.research.ibm.com/artificial-intelligence/trusted-ai' target='_blank'>Trusted AI</Link> include:
                </p>
                <br/>
                <p>
                    <Link href="https://aif360.res.ibm.com/" target='_blank' className="link-with-icon">AI Fairness 360</Link><br/>
                    <Link href="https://aix360.res.ibm.com/" target='_blank' className="link-with-icon">AI Explainability 360</Link><br/>
                    <Link href="https://art360.res.ibm.com/" target="_blank" className="link-with-icon">Adversarial Robustness 360</Link><br/>
                    <Link href="https://aifs360.res.ibm.com/" target="_blank" className="link-with-icon">AI Factsheets 360</Link><br/>
                </p>
            </div>
        </div>
    </section>
);