import React, { useState } from 'react';
import {
  Header,
  HeaderName,
  HeaderGlobalBar,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderContainer,
  HeaderMenuButton,
  SideNavLink,
  HeaderGlobalAction,
  HeaderPanel,
  Switcher,
  SwitcherItem,
  SwitcherDivider,
  SideNavMenu,
} from 'carbon-components-react/lib/components/UIShell';

import { AppSwitcher20 } from '@carbon/icons-react';


import "./AppHeader.scss";
import { NavLink } from 'react-router-dom';

export const AppHeader = () => {
  const [switcherExpanded, setSwitcherExpanded] = useState(false);
  // Friendly component :-)
  const SideNavRouterLink = (props: any) => (
    <SideNavLink element={ NavLink } { ...props } >{props.children}</SideNavLink>
  )

  return <div className="container">
    <HeaderContainer aria-label="" aria-labelledby=""
      render={(props: any) => {
        const { onClickSideNavExpand, isSideNavExpanded} = props;
        window.addEventListener(
          'resize',
          () => {
            const viewportWidth =
              window.innerWidth || document.documentElement.clientWidth;
            if (viewportWidth > 1056) {
              if (isSideNavExpanded === true) onClickSideNavExpand();
            }
          },
          false
        );
        return <>
          <Header aria-label="IBM Research Uncertainty Quantification 360" onClick= {isSideNavExpanded === true ? onClickSideNavExpand : null}>
            <SkipToContent />
            <HeaderMenuButton
              aria-label="Open menu"
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
            />
            <HeaderName href="/" prefix="IBM Research">
              Uncertainty Quantification 360
            </HeaderName>
            <HeaderGlobalBar>
              <HeaderGlobalAction aria-label="App Switcher" isActive onClick={() => setSwitcherExpanded(!switcherExpanded)}>
                <AppSwitcher20/>
              </HeaderGlobalAction>
            </HeaderGlobalBar>
            <HeaderPanel expanded={switcherExpanded} aria-label="" aria-labelledby="">
              <Switcher aria-label="" aria-labelledby="">
                <SwitcherItem href='https://www.research.ibm.com/' target="_blank" aria-label="" aria-labelledby="">IBM Research</SwitcherItem>
                <SwitcherItem href='https://www.research.ibm.com/artificial-intelligence/trusted-ai/' target="_blank" aria-label="" aria-labelledby="">IBM Research Trusted AI</SwitcherItem>
                <SwitcherDivider/>
                <SwitcherItem href='https://aif360.res.ibm.com/' target="_blank" aria-label="" aria-labelledby="">AI Fairness 360</SwitcherItem>
                <SwitcherItem href='https://aix360.res.ibm.com/' target="_blank" aria-label="" aria-labelledby="">AI Explainability 360</SwitcherItem>
                <SwitcherItem href='https://art360.res.ibm.com/' target="_blank" aria-label="" aria-labelledby="">AI Adversarial Robustness 360</SwitcherItem>
                <SwitcherItem href='https://aifs360.res.ibm.com/' target="_blank" aria-label="" aria-labelledby="">AI Factsheets 360</SwitcherItem>
                <SwitcherItem href='/' aria-label="" aria-labelledby="">Uncertainty Quantification 360</SwitcherItem>
              </Switcher>
            </HeaderPanel>
            <SideNav
              aria-label="Side navigation"
              expanded={isSideNavExpanded}
              isChildOfHeader={true}
            >
            <SideNavItems>
                <SideNavRouterLink exact to='/'>Home</SideNavRouterLink>
                <SideNavRouterLink exact to='/overview'>Overview</SideNavRouterLink>
                {/* <SideNavMenu title="Demos" defaultExpanded>
                  <SideNavRouterLink exact to="/demos/regression">House price prediction (regression)</SideNavRouterLink>
                  <SideNavRouterLink exact to="/demos/classification">Income prediction (classification)</SideNavRouterLink>
                </SideNavMenu> */}

                <SideNavRouterLink exact to="/demo/0">Demo</SideNavRouterLink>
                
                <SideNavMenu title="Resources" defaultExpanded>
                  <SideNavRouterLink exact to="/resources/guidance">Guidance</SideNavRouterLink>
                  <SideNavRouterLink exact to="/resources/communication">Communicate Uncertainty</SideNavRouterLink>
                  <SideNavRouterLink exact to="/resources/glossary">Glossary</SideNavRouterLink>
                  <SideNavRouterLink exact to="/resources/papers">Our Papers</SideNavRouterLink>
                  <SideNavRouterLink exact to="/resources/tutorials">Tutorials</SideNavRouterLink>
                </SideNavMenu>


                {/* <SideNavRouterLink exact to="/resources/events">Events</SideNavRouterLink> */}
                {/* <SideNavRouterLink exact to="/resources/videos">Videos</SideNavRouterLink> */}
                <SideNavRouterLink exact to="/community">Community</SideNavRouterLink>
              </SideNavItems>
            </SideNav>
          </Header>
        </>
      }}
    />
  </div>
};
