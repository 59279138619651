import React from "react";
import "./App.scss";

import { Content } from "carbon-components-react";
import { AppHeader } from "./components";
import { Route, Switch } from "react-router-dom";
import {
  Home,
  Overview,
  Events,
  NotFound,
  SlackCommunity,
  RegressionDemo,
  Glossary,
  Guidance,
  Papers,
  CommGuide,
  Tutorials,
} from "./content";

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <AppHeader />
      </header>
      <Content>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/overview" component={Overview} />
          <Route path="/demo" component={RegressionDemo} />
          <Route exact path="/resources/papers" component={Papers} />
          <Route exact path="/resources/communication" component={CommGuide} />
          <Route exact path="/community" component={SlackCommunity} />
          <Route exact path="/resources/guidance" component={Guidance} />
          <Route exact path="/resources/events" component={Events} />
          <Route exact path="/resources/glossary" component={Glossary} />
          <Route exact path="/resources/tutorials" component={Tutorials} />
          <Route component={NotFound} />
        </Switch>
      </Content>
    </div>
  );
};

export default App;
