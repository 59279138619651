import { LinkableText } from "../components"

export type Event = {
    date: string, // expected format: "YYYY/m/d"
    links: LinkableText[],
    title: string,
    type?: string,
    venue: LinkableText[],
}

export const events : Event[] = [
    // {
    //     date: "2020/6/17",
    //     links: [
    //         { text: "Video", url: "https://www.youtube.com/watch?v=V_ivECnlQGM" }
    //     ],
    //     title: "Increasing Trust in AI",
    //     type: "guest lecture",
    //     venue: [
    //         { text: "Ethical Implications of AI", url: "http://www.bigdata.uni-frankfurt.de/ethical-implications-ai-ss2020/"},
    //         { text: "Goethe Universtat Frankfurt" }
    //     ]
    // },
]