import React from "react";

import {
  FS_ICON_INTRODUCTION,
  FS_ICON_AUTHORING,
  FS_ICON_SLACK,
  FS_ICON_PAPERS,
} from "../utils";
import { AppCard, AppFooter } from "../../components";

import "./Home.scss";
import { Button } from "carbon-components-react";
import { PlayOutline16, LogoGithub16 } from "@carbon/icons-react";

const algorithms = {
  "Auxiliary Interval Predictor": {
    desc: "Use an auxiliary model to improve the calibration of UQ generated by the original model.",
    link: "https://uq360.readthedocs.io/en/latest/extrinsic.html#auxiliary-interval-predictor",
  },
  "Blackbox Metamodel Classification": {
    desc: "Extract confidence scores from trained black-box classification models using a meta-model.",
    link: "https://uq360.readthedocs.io/en/latest/extrinsic.html#blackbox-metamodel-classification",
  },
  "Blackbox Metamodel Regression": {
    desc: "Extract prediction intervals from trained black-box regression models using a meta-model.",
    link: "https://uq360.readthedocs.io/en/latest/extrinsic.html#blackbox-metamodel-regression",
  },
  "Classification Calibration": {
    desc: "Post-hoc calibration of classification models using Isotonic Regression and Platt Scaling.",
    link: "https://uq360.readthedocs.io/en/latest/extrinsic.html#classification-calibration",
  },
  "Heteroscedastic Regression": {
    desc: "Train regression models that capture data uncertainty, assuming the targets are noisy and the amount of noise varies between data points.",
    link: "https://uq360.readthedocs.io/en/latest/intrinsic.html#heteroscedastic-regression",
  },
  "Homoscedastic Gaussian Process Regression": {
    desc: "Train Gaussian Process Regression models with homoscedastic noise that capture data and model uncertainty.",
    link: "https://uq360.readthedocs.io/en/latest/intrinsic.html#homoscedastic-gaussian-process-regression",
  },
  "Horseshoe BNN classification": {
    desc: "Train Bayesian neural networks classifiers with Gaussian and Horseshoe priors that capture data and model uncertainty.",
    link: "https://uq360.readthedocs.io/en/latest/intrinsic.html#bayesian-neural-network-classification",
  },
  "Horseshoe BNN regression": {
    desc: "Train BNNs regression models with Gaussian and Horseshoe priors that capture data and model uncertainty.",
    link: "https://uq360.readthedocs.io/en/latest/intrinsic.html#bayesian-neural-network-regression",
  },
  "Infinitesimal Jackknife": {
    desc: "Extract uncertainty from trained models by approximating the effect of training data perturbations on the model’s predictions.",
    link: "https://uq360.readthedocs.io/en/latest/extrinsic.html#infinitesimal-jackknife",
  },
  "Quantile Regression": {
    desc: "Train Quantile Regression models that capture data uncertainty, by learning two separate models for the upper and lower quantile to obtain the prediction intervals.",
    link: "https://uq360.readthedocs.io/en/latest/intrinsic.html#quantile-regression",
  },
  "UCC Recalibration": {
    desc: "Recalibrate UQ of a regression model to specified operating point using Uncertainty Characteristics Curve.",
    link: "https://uq360.readthedocs.io/en/latest/extrinsic.html#ucc-recalibration",
  },
};

const metrics = {
  "Classification Metrics": {
    desc: "Include Expected Calibration Error, Brier Score, etc. for classification models. Several diagnosis tools such as reliability diagram and risk-vs-rejection rate curves are also provided.",
    link: "https://uq360.readthedocs.io/en/latest/classification_metrics.html",
  },
  "Regression Metrics": {
    desc: "Include Prediction Interval Coverage Probability (PICP) and Mean Prediction Interval Width (MPIW) among others.",
    link: "https://uq360.readthedocs.io/en/latest/regression_metrics.html"
  },
  "Uncertainty Characteristic Curve": {
    desc: "A novel operation-point agnostic approach for evaluating UQ, allowing comparison of the trade-off between PICP and MPIW.",
    link: "https://uq360.readthedocs.io/en/latest/regression_metrics.html#uncertainty-characteristics-curve"
  }
}

export const Home = () => {
  const content = (
    <div className="bx--grid bx--grid--full-width bx--grid--no-gutter">
      <section className="bx--row stripe">
        <div className="bx--col-sm-4">
          <div className="cell-wrapper">
            {/* <img alt="UQ360 Logo" id='uq360-img'></img> */}
            <div className="home-logo-title">
              Uncertainty Quantification 360
            </div>
            <div className="home-logo-text">
              Uncertainty quantification (UQ) gives AI the ability to express
              that it is <i>unsure</i>, adding critical transparency for the safe
              deployment and use of AI. This extensible open source toolkit can
              help you estimate, communicate and use uncertainty in machine
              learning model predictions through an AI application lifecyle. We
              invite you to use it and improve it.
            </div>
            <div className="home-logo-video-button float-left">
              <Button
                kind="primary"
                size="default"
                renderIcon={PlayOutline16}
                href="https://uq360.readthedocs.io/"
                target="_blank"
              >
                API Docs
              </Button>
            </div>
            <div className="home-logo-video-button float-left">
              <Button
                kind="secondary"
                size="default"
                renderIcon={LogoGithub16}
                href="https://github.com/IBM/UQ360"
                target="_blank"
              >
                Get Code
              </Button>
            </div>
          </div>
        </div>
      </section>
      <hr id="content-separator" />
      <section className="bx--row grey stripe">
        <div className="bx--col-lg-16">
          <div className="cell-wrapper">
            <p className="section-title">Start Here</p>
            <div className="home-cards">
              <AppCard
                icon={FS_ICON_INTRODUCTION}
                href="/overview"
                title="Overview of UQ360"
              ></AppCard>
              <AppCard
                icon={FS_ICON_AUTHORING}
                href="/demo/0"
                title="Try a Web Demo to Explore UQ Capabilities"
              ></AppCard>
              <AppCard
                icon={FS_ICON_PAPERS}
                href="/resources/papers"
                title="Read Our Papers"
              ></AppCard>
              <AppCard
                icon={FS_ICON_SLACK}
                href="/community"
                title="Join UQ360 Community"
              ></AppCard>
            </div>
          </div>
        </div>
      </section>
      <section className="bx--row grey stripe">
        <div className="bx--col-lg-16">
          <div className="cell-wrapper">
            <p className="section-title">Tutorials</p>
            <div className="home-cards">
              <AppCard
                href="https://github.com/IBM/UQ360/blob/main/examples/tutorials/tutorial-synthetic_housing_dataset.ipynb"
                title="Calibrated Housing Price Prediction"
                style={{ backgroundColor: "rgb(166, 200, 255)" }}
                external
              >
                See how to generate and use UQ for a regression
                model to help decision-makers set the right housing price.
              </AppCard>
              <AppCard
                href="https://github.com/IBM/UQ360/blob/main/examples/tutorials/tutorial-adult_income_dataset.ipynb"
                title="Selective Classification on Adult Income Dataset"
                style={{ backgroundColor: "rgb(166, 200, 255)" }}
                external
              >
                See how to generate UQ for a classification model making income
                predictions, and use UQ to compare the model performance for
                different demographic groups.
              </AppCard>
            </div>
          </div>
        </div>
      </section>
      <section className="bx--row grey stripe last-section">
        <div className="bx--col-lg-16">
          <div className="cell-wrapper">
            <p className="section-title">Algorithms</p>
            <div className="home-cards">
              {Object.entries(algorithms).map((entry) => {
                const [name, algo] = entry;
                return (
                  <AppCard
                    href={algo.link}
                    title={name}
                    style={{ backgroundColor: "rgb(229, 216, 253)" }}
                    external
                  >
                    {algo.desc}
                  </AppCard>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="bx--row grey stripe last-section">
        <div className="bx--col-lg-16">
          <div className="cell-wrapper">
            <p className="section-title">Metrics</p>
            <div className="home-cards">
              {Object.entries(metrics).map((entry) => {
                const [name, metric] = entry;
                return (
                  <AppCard
                    href={metric.link}
                    title={name}
                    style={{ backgroundColor: "rgb(174, 235, 235)" }}
                    external
                  >
                    {metric.desc}
                  </AppCard>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <AppFooter />
    </div>
  );

  return <>{content}</>;
};
