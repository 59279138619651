import React, { useState } from "react";
import { ReactComponent as DataScientist } from "../../assets/icons/DataScientist.svg";
import {
  ContentSwitcher,
  Switch as ContentGroup,
  Button,
} from "carbon-components-react";
import { Link, useHistory } from "react-router-dom";
import Term from "../Term/Term";

export function Calibration() {
  let [mode, setMode] = useState("num_rooms");
  const history = useHistory();
  return (
    <>
      <div className="bx--row">
        <div className="bx--col-lg-2">
          <DataScientist width="90px" height="90px" />
        </div>
        <div className="bx--col-lg-14">
          <h3>Evaluate and improve UQ quality</h3>
          <p>
            John, the data scientist wants to know
            <br />
            <span className="question">
              Do I need to, and if so, how to improve the quality of the
              uncertainty estimates?
            </span>
          </p>
        </div>
      </div>
      <p>
        When a UQ method is chosen, it should always be evaluated before being
        shown to users in deployment. Like a model’s prediction performance, the
        quality of UQ should also be evaluated with a set of test data. The
        quality of UQ is often improved through calibration. With a
        well-calibrated UQ method, if the prediction interval covers the right
        price with 95% confidence, then close to 95% of the test data should
        fall within the prediction interval. Otherwise the UQ information could
        be unreliable, and users should not be presented with such information
        or at least alerted. You can read more about UQ evaluation in the{" "}
        <Link to="/resources/guidance">
          Guidance on Choosing UQ Algorithms and Metrics
        </Link>
        .
      </p>
      <p>
        To assess the calibration of the UQ method, John uses a metric called{" "}
        <Term>Prediction Interval Coverage Probability (PICP)</Term>, included
        in UQ360. Formally, PICP is defined as the fraction of a sample (e.g.,
        test data) covered by the prediction interval. A well-calibrated UQ
        method should have a PICP close to the set confidence probability of the
        uncertainty estimates (95 % in this case).
      </p>
      <p>
        The calibration could vary for subsets of the data. UQ360 offers a{" "}
        <a
          href="https://uq360.readthedocs.io/en/latest/regression_metrics.html#uq360.metrics.regression_metrics.plot_picp_by_feature"
          target="_blank"
          rel="noopener noreferrer"
        >
          function
        </a>{" "}
        to plot PICP by different values of a feature, together with the total
        PICP for John to look at. Select a feature below and see what John
        learns.
      </p>
      <div style={{ marginTop: "4em", marginBottom: "4em" }}>
        <ContentSwitcher
          style={{ width: "30em", marginBottom: "10px" }}
          onChange={(obj) => {
            let { name } = obj;
            if (typeof name === "string") setMode(name);
          }}
        >
          <ContentGroup name="num_rooms" text="Number of rooms" />
          <ContentGroup name="crime_rate" text="Crime rate per capita" />
        </ContentSwitcher>
        <div className="bx--row">
          <div className="bx--col-lg-8">
            <img
              src={`/imgs/picp_${mode}_before_calib.png`}
              alt={`PICP of the model before uncertainty calibration as a function of ${mode}`}
            ></img>
          </div>
          <div className="bx--col-lg-8 sidebox">
            {mode === "num_rooms" && (
              <>
                <p>
                  John observes that the PICP score drops significantly below
                  the desired confidence 95% for houses with more than 5 rooms.
                  This tells John that the UQ information is mis-calibrated for
                  houses with a larger number of rooms and could be misleading
                  to show to sellers.
                </p>
              </>
            )}
            {mode === "crime_rate" && (
              <>
                <p>
                  John observes that the PICP is constantly below the set
                  confidence 95%. This tells John that the UQ information is
                  mis-calibrated and could be misleading to show to sellers.
                </p>
              </>
            )}
          </div>
        </div>
        <p>
          UQ360 provides several methods for John to improve the calibration of
          UQ information. John chooses to re-calibrate the prediction interval
          using{" "}
          <a
            href="https://uq360.readthedocs.io/en/latest/regression_metrics.html#uncertainty-characteristics-curve"
            target="_blank"
            rel="noopener noreferrer"
          >
            Uncertainty Characteristic Curve
          </a>{" "}
          to improve the PICP score. Then he assesses the calibration again.
        </p>
        <div className="bx--row">
          <div className="bx--col-lg-8">
            <img
              src={`/imgs/picp_${mode}_after_calib.png`}
              alt={`PICP of the model after uncertainty calibration as a function of ${mode}`}
            ></img>
          </div>
          <div className="bx--col-lg-8 sidebox">
            {mode === "num_rooms" && (
              <>
                <p>
                  John observes that the new total PICP is higher than before.
                  Especially for houses with more than 5 rooms, the PICP
                  increased to close to 95% or higher.
                </p>
                <p>
                  He is satisfied with the quality of the new UQ and updates the
                  model.
                </p>
              </>
            )}
            {mode === "crime_rate" && (
              <>
                <p>
                  John observes that the new total PICP is higher than before
                  and almost across board for all CRIM values.
                </p>
                <p>
                  He is satisfied with the quality of the new UQ and updates the
                  model.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <p>
        It is worth pointing out that improving calibration does not mean
        reducing uncertainty. In fact, as a result of the re-calibration, the
        uncertainty level, captured by a metric called{" "}
        <Term>Mean Prediction Interval Width (MPIW)</Term>, increased. Increased
        prediction intervals would explicitly tell sellers that the model is
        less certain about recommended prices as the actual value can fall in a
        larger range. Sellers can confidently use the prediction interval as a
        recommended price range, though they may still need to rely on their own
        experience to set an optimal price within that relatively larger
        interval.
      </p>
      <p>
        Next, John can choose to further improve the model guided by the UQ
        information, or you can go back to the tasks page to check out a
        different task.
      </p>
      <Button onClick={() => history.push("/demo/2/improve")}>
        Next: Improve the model
      </Button>
    </>
  );
}
