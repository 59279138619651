import React, { useState } from "react";
import { ReactComponent as DataScientist } from "../../assets/icons/DataScientist.svg";
import {
  Button,
  ContentSwitcher,
  Switch as ContentGroup,
} from "carbon-components-react";
import { useHistory } from "react-router-dom";
import Term from "../Term/Term";

export function Improve() {
  let [mode, setMode] = useState("adding_feature");
  const history = useHistory();

  return (
    <>
      <div className="bx--row">
        <div className="bx--col-lg-2">
          <DataScientist width="90px" height="90px" />
        </div>
        <div className="bx--col-lg-14">
          <h3>Use UQ to improve the model</h3>
          <p>
            John, the data scientist wants to know
            <br />
            <span className="question">
              Do I need to, and if so, how can I reduce data uncertainty?
              <br />
              Do I need to, and if so, how can I reduce model uncertainty?
            </span>
          </p>
        </div>
      </div>
      <p>
        In the longer term, John would like to create a new, improved version of
        the model that has generally lower uncertainty, i.e. narrower prediction
        intervals, formally defined as{" "}
        <Term>Mean Prediction Interval Width (MPIW)</Term>, so that sellers
        could make easier price decisions.
      </p>
      <p>
        UQ can guide him in this process. With intrinsic UQ methods such as{" "}
        <a
          href="https://uq360.readthedocs.io/en/latest/intrinsic.html#homoscedastic-gaussian-process-regression"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gaussian processes
        </a>
        , predictive uncertainty captures both <Term>data uncertainty</Term> and{" "}
        <Term>model uncertainty</Term>. To understand where the uncertainty
        comes from for a model and how to improve it, it is helpful to examine
        data uncertainty and model uncertainty separately.
      </p>

      <p>
        Select below to see how John could improve the model based on different
        types of UQ information.
      </p>
      <div style={{ marginTop: "4em", marginBottom: "4em" }}>
        <ContentSwitcher
          style={{ width: "30em", marginBottom: "10px" }}
          onChange={(obj) => {
            let { name } = obj;
            if (typeof name === "string") setMode(name);
          }}
        >
          <ContentGroup name="adding_feature" text="Reduce data uncertainty" />
          <ContentGroup
            name="adding_training_points"
            text="Reduce model uncertainty"
          />
        </ContentSwitcher>

        {mode === "adding_feature" && (
          <>
            <p>
              <Term>Data uncertainty</Term> refers to the random fluctuations
              between the input features and the outcome in the training data.
              For example, houses of a similar size or with similar number of
              rooms could have very different selling prices.
            </p>
            <p>
              One way to reduce data uncertainty is by adding new features that
              could account for the fluctuation. For example, the price
              differences between two houses with the same number of rooms could
              be explained by the differences between the neighborhoods they are
              in, such as available resources for education.
            </p>
          </>
        )}
        {mode === "adding_training_points" && (
          <>
            <p>
              <Term>Model uncertainty</Term> arises when multiple model
              parameter configurations can sufficiently fit the training data.
              So there is uncertainty in the chosen model configuration, because
              each alternative configuration could lead to a different
              prediction.
            </p>
            <p>
              In general, model uncertainty can be mitigated by collecting more
              training data to improve the fitting and filter out alternative
              model configurations. Examining the detailed model UQ information
              by different feature-values could guide the data collection
              choices.
            </p>
          </>
        )}
        <div className="bx--row">
          <div className="bx--col-lg-8">
            <img
              src={`/imgs/uncertainty_before_${mode}.png`}
              alt={`Uncertainty before ${mode}`}
            ></img>
            <img
              src={`/imgs/uncertainty_after_${mode}.png`}
              alt={`Uncertainty after ${mode}`}
            ></img>
          </div>
          <div className="bx--col-lg-8 sidebox">
            {mode === "adding_feature" && (
              <>
                <p>
                  With the old model, John observes that data uncertainty
                  contributes to the total uncertainty (as model uncertainty
                  only accounts for a part of the total uncertainty).
                </p>
                <p>
                  To explore whether the data uncertainty could be reduced, John
                  asks the team to add a new feature to the dataset that could
                  differentiate the neighborhood resources for similar houses:
                  pupil-teacher ratio in a neighborhood (PTRATIO). He then
                  re-trains the model.
                </p>
                <p>
                  With the new model, he observes the prediction intervals and
                  overall MPIW decreased. John is happy about the improvement
                  and approves the new model for sellers to use.
                </p>
              </>
            )}
            {mode === "adding_training_points" && (
              <>
                <p>
                  With the old model, John observes that the predictive
                  intervals for model uncertainty are larger for houses with
                  more than 5 rooms. This pattern implies that the model has not
                  seen enough examples of houses in that range.
                </p>
                <p>
                  John asks the team to collect more data for houses with more
                  than 5 rooms, then retrains the model.
                </p>
                <p>
                  With the new model, the prediction intervals and the overall
                  MPIW narrowed. John is happy about the improvement and
                  approves the new model for sellers to use.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <p>
        Next, John can let the sellers use the model with UQ information.
        Usually, end users will only need to see the total predictive
        uncertainty. Or you can go back to the tasks page to check out a
        different task.
      </p>
      <Button onClick={() => history.push("/demo/2/decision")}>
        Next: Use the model
      </Button>
    </>
  );
}
