import React from "react";
import { SimpleContentPage } from "../../components";
import { Tabs, Tab } from "carbon-components-react";

import "./Overview.scss";
import { Link } from "react-router-dom";
import Term from "../Term/Term";

export const Overview = () => (
  <SimpleContentPage
    key="Overview"
    title="Introduction to Uncertainty Quantification 360"
  >
    <Tabs type="container" light>
      <Tab label="Overview">
        <p>
          There are inherent uncertainties in the predictions of machine
          learning (ML) models. Knowing how uncertain the prediction might be
          influences how people act on it. For example, you may have encountered
          a weather-forecasting model telling you that there will be no rain
          tomorrow with 60% confidence. In that case you may still want to bring
          an umbrella when you go to work.
        </p>
        <p>
          There is more to uncertainty in ML than the example above. Uncertainty
          is an important area of ML research, which has produced an abundance
          of uncertainty quantification (UQ) algorithms, metrics, and ways to
          communicate uncertainty to end users. Researchers at IBM Research have
          been actively working on these topics to enable critical transparency
          to ML models and engender{" "}
          <a
            href="https://www.research.ibm.com/artificial-intelligence/trusted-ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            trust in AI
          </a>
          . You can <Link to="/resources/papers">read our papers</Link>.
        </p>
        <p>
          We present Uncertainty Quantification 360 (UQ360), an extensive
          open-source toolkit with a Python package to provide data science
          practitioners and developers access to state-of-the-art algorithms, to
          streamline the process of estimating, evaluating, improving, and
          communicating uncertainty of machine learning models as common
          practices for AI transparency.
        </p>
        <img
          src="/imgs/flowchart.png"
          alt="Flowchart to use UQ360"
          width="100%"
        ></img>
        <p>
          In this overview, we introduce some core concepts in uncertainty
          quantification. You can use the navigation bar above to navigate to
          the two questions we will answer:
          <ul>
            <li>What capabilities are included in UQ360 and why?</li>
            <li>
              How can you use the uncertainty information produced using UQ360?
            </li>
          </ul>
        </p>
        <p>
          An <Link to="/demo/0">interactive demo</Link> allows you to further
          explore these concepts and capabilities offered by UQ360 by walking
          through a use case where different tasks involving UQ are performed.
          The <Link to="/resources/tutorials">tutorials and notebooks</Link>{" "}
          offer a deeper, data scientist-oriented introduction. A{" "}
          <a
            href="https://uq360.readthedocs.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            complete API
          </a>{" "}
          is also available.
        </p>
      </Tab>
      <Tab label="UQ360 Capabilities">
        <h3>Why do we include many different UQ capabilities?</h3>
        <p>
          Our goal is to provide diverse capabilities to streamline the process
          of producing high-quality UQ information during model development
          that: 1) estimate the uncertainty in predictions of an ML model, 2)
          evaluate the quality of those uncertainties, and if needed improve
          their quality, and 3) communicate those uncertainties effectively to
          people who can make use of the UQ information.
        </p>
        <p>Let’s start with the first objective.</p>
        <p>
          Supervised ML typically involves learning a functional mapping between
          inputs (features) and outputs (predictions/recommendations/responses)
          from a set of training examples comprising input and output pairs. The
          learned function is then used to predict outputs for new instances or
          inputs not seen during training. These outputs may be real values in
          the case of a regression model, or categorical class labels in the
          case of a classification model. In this process, uncertainty can
          emerge from multiple sources:
          <ul>
            <li>
              The available data may be inherently noisy; for instance, two
              examples with the same feature profiles may have different
              outcomes. This is often termed aleatoric uncertainty in the
              literature. Going forward, we will simply refer to it as{" "}
              <Term>data uncertainty</Term>.
            </li>
            <li>
              The model mapping function may be ambiguous: given a set of
              training data, there may be different functions that explain them.
              This uncertainty about the model is termed epistemic uncertainty
              in the literature. We will simply refer to it as{" "}
              <Term>model uncertainty</Term>.
            </li>
          </ul>
          The uncertainty around the predictions of a machine learning model, <Term>predictive uncertainty</Term>, is the aggregate of model and data uncertainties.
        </p>
        <p>
          The UQ360 toolkit provides algorithms to estimate these different
          types of uncertainties. Depending on the type of model and the model
          development stage, different UQ algorithms should be applied. UQ360
          currently provides 11 UQ algorithms, and a{" "}
          <Link to="/resources/guidance">
            guidance on choosing UQ algorithms
          </Link>{" "}
          to help you find the appropriate one for your use case.
        </p>
        <p>
          Moving on to our second goal: the quality of uncertainties generated
          by a UQ algorithm also needs to be evaluated. Poor-quality
          uncertainties should neither be trusted nor communicated to users.
          They should first be improved. To this end, UQ360 provides a set of{" "}
          <a
            href="https://uq360.readthedocs.io/en/latest/metrics.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            metrics to measure the quality of uncertainties
          </a>{" "}
          produced by different algorithms and a set of{" "}
          <a
            href="https://uq360.readthedocs.io/en/latest/extrinsic.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            techniques for improving the quality
          </a>{" "}
          of estimated uncertainties. You can read more about them in the{" "}
          <Link to="/resources/guidance">
            guidance on choosing UQ algorithms
          </Link>
          .
        </p>
        <p>
          Lastly, the best way to communicate uncertainty estimates depends on the
          type of the model, the way the UQ information is going to be used, and
          the recipients of the information. For a classification model like the
          one for weather-forecasting, the uncertainty is typically a score that
          is often referred to as <strong>confidence</strong>. For a regression model, the
          uncertainty could be communicated in various ways, including a range
          in which the predicted outcome can possibly fall, often referred to as{" "}
          <strong>prediction interval</strong>, or using visualizations. UQ360
          provides a{" "}
          <Link to="/resources/communication">
            guidance for communicating uncertainty
          </Link>{" "}
          to help you choose an appropriate way to present uncertainty
          quantification.
        </p>
      </Tab>
      <Tab label="How to use UQ">
        <h3>How can you use UQ?</h3>
        <p>
          Assuming that you have chosen an appropriate UQ algorithm, generated
          high-quality uncertainty estimates and properly communicated them to the
          recipients, you may ask, how can they use such information?
        </p>
        <p>
          For end-users of an AI system, UQ adds critical transparency to its
          predictions, helping them assess whether they want to trust (and
          accept) a particular prediction, whether they should gather more
          information, or resort to an alternative judgment. It is imperative to
          present UQ information in high-stakes applications such as healthcare,
          finance, and security to prevent over-reliance on AI and facilitate
          better decision-making. For example, estimated prediction intervals of a regression
          model's prediction can be used to better assess what the actual
          outcome may be, as shown in the{" "}
          <Link to="/demo/0">interactive demo</Link>.
        </p>
        <p>
          UQ is also useful to model developers as a tool for improving their
          model. By estimating model uncertainty and data uncertainty
          separately, UQ helps the developers diagnose the source of
          uncertainty and guide the model improvement strategy. For example,
          model uncertainty can be reduced by collecting more training data in
          regions of high model uncertainty. Data uncertainty can be reduced by
          improving the measurement process or creating additional features that
          account for the variability in the training data. See the{" "}
          <Link to="/demo/0">interactive demo</Link> for a data scientist's
          experience using UQ.
        </p>
        <h3>Developing UQ capabilities for trustworthy AI</h3>
        <p>
          We have developed UQ360 to disseminate the latest research and
          educational materials for producing and applying uncertainty
          quantification in an AI lifecyle . This is a growing area and we have
          developed this toolkit with extensibility in mind. We encourage the
          contribution of your UQ capabilities by joining the{" "}
          <Link to="/community">community</Link>. We also encourage the
          exploration of UQ's connection to other pillars of Trustworthy AI,
          namely{" "}
          <a
            href="https://aif360.res.ibm.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            fairness
          </a>
          ,{" "}
          <a
            href="https://aix360.res.ibm.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            explainability
          </a>
          ,{" "}
          <a
            href="https://art360.res.ibm.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            adversarial robustness
          </a>
          , and{" "}
          <a
            href="https://aifs360.res.ibm.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            FactSheets
          </a>
          .
        </p>
      </Tab>
    </Tabs>
  </SimpleContentPage>
);
