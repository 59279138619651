import React from "react";
import { Switch, useHistory, useRouteMatch, Route } from "react-router-dom";
import {
  ClickableTile,
  Button,
  ProgressIndicator,
  ProgressStep,
} from "carbon-components-react";
import Term from "../Term/Term";
import { ReactComponent as DataScientist } from "../../assets/icons/DataScientist.svg";
import { ReactComponent as BusinessOwner } from "../../assets/icons/BusinessOwner.svg";

import "./Demo.scss";
import { Link } from "react-router-dom";
import { Decision } from "./Decision";
import { Improve } from "./Improve";
import { Calibration } from "./Calibration";
import { Choose } from "./Choose";

const taskOptions = {
  choose: {
    text: "Step 1. Choose a UQ method",
    description:
      "Data scientist can choose a UQ method suitable for the task from many methods offered by UQ360",
    logo: DataScientist,
  },
  calibration: {
    text: "Step 2. Evaluate and improve UQ quality",
    description:
      "Data scientist can improve the quality (calibration) of the UQ output with a re-calibration method offered by UQ360",
    logo: DataScientist,
  },
  improve: {
    text: "Step 3. Use UQ to improve the model",
    description:
      "Data scientist can use the UQ information to decide on how to improve the model",
    logo: DataScientist,
  },
  decision: {
    text: "Step 4. Use UQ to make decisions",
    description:
      "Seller can use the UQ information to set better house prices. UQ360 offers multiple ways to communicate UQ based on the user’s needs",
    logo: BusinessOwner,
  },
};

function Tile(props) {
  const history = useHistory();
  let task = taskOptions[props.task];
  return (
    <ClickableTile
      className="presentation-rectangular"
      id={`tile-${props.task}`}
      key={`tile-${props.task}`}
      handleClick={() => history.push("/demo/2/" + props.task)}
    >
      <div className="presentation-full-tile">
        <div className="presentation-tile-image">
          <task.logo />
        </div>

        <div className="presentation-tile-text">
          <div className="presentation-tile-text-title">{task.text}</div>
          <div className="presentation-tile-text-description">
            {task.description}
          </div>
        </div>
      </div>
    </ClickableTile>
  );
}

function Task() {
  return (
    <div className="bx--col-lg-16">
      <Switch>
        <Route exact path="/demo/2/choose" component={Choose}></Route>
        <Route exact path="/demo/2/calibration" component={Calibration}></Route>
        <Route exact path="/demo/2/improve" component={Improve}></Route>
        <Route exact path="/demo/2/decision" component={Decision}></Route>
      </Switch>
    </div>
  );
}

export const RegressionDemo = () => {
  let match = useRouteMatch("/demo/:step");
  let step = parseInt(match.params["step"]);
  const history = useHistory();

  return (
    <div className="bx--grid bx--grid--full-width bx--grid--no-gutter">
      <section className="bx--row demo-top">
        <div className="demo-title-wrapper">
          <div className="logo-title">Demo: House Price Prediction Model</div>
        </div>
      </section>
      <hr id="content-separator" />
      <section className="bx--row grey">
        <div className="demo-content-wrapper">
          <div className="bx--row">
            <div className="bx--col-lg-12">
              <ProgressIndicator
                style={{ marginTop: "1em" }}
                currentIndex={step}
              >
                <ProgressStep label="Model"></ProgressStep>
                <ProgressStep label="Choose Task"></ProgressStep>
                <ProgressStep label="Use of UQ"></ProgressStep>
              </ProgressIndicator>
            </div>
            <div className="bx--col-lg-4">
              {step > 0 && (
                <Button
                  kind="secondary"
                  onClick={() => history.push("/demo/" + (step - 1))}
                >
                  Back
                </Button>
              )}
            </div>
          </div>
          <div className="factsheet">
            <div className="bx--grid fs-web-container">
              <div className="bx--row fs-web-content">
                <Switch>
                  <Route exact path="/demo/0">
                    <div className="bx--col-lg-16">
                      <p>
                        A machine learning model was built to assist sellers of
                        real estate properties by suggesting house
                        prices. This is a regression model (interested in UQ for
                        other types of models? Check out our{" "}
                        <Link to="/overview">Overview</Link>), for which the
                        uncertainty can be expressed as a{" "}
                        <Term>prediction interval</Term> or a distribution
                        of possible predicted outcomes.
                      </p>

                      <p>
                        This model was trained on a dataset of past house sales,
                        which include recorded selling prices and various
                        features of the sold houses. For simplicity, this demo
                        will illustrate the model with two common features that
                        often matter for house prices: number of rooms (RM) and
                        crime rate per capita in a neighborhood (CRIM). RM is a
                        feature of discrete value and CRIM is of continuous
                        value. The model is trained based on an adapted version
                        of this{" "}
                        <a
                          href="https://www.kaggle.com/c/boston-housing/overview"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          dataset
                        </a>
                        .
                      </p>

                      <p>In this demo, you will meet the following people:</p>
                      <div className="bx--row">
                        <div
                          className="bx--col-lg-4"
                          style={{ textAlign: "right" }}
                        >
                          {/* <img
                            src="/imgs/John.png"
                            style={{ width: 80 }}
                            alt="John"
                          /> */}
                          <DataScientist width="100px" height="100px" />
                        </div>
                        <div className="bx--col-lg-12">
                          <h4>John—a data scientist</h4>
                          <p>
                            John develops the machine learning model and is
                            responsible for choosing an appropriate method for
                            UQ. John would also like to keep improving the model
                            and uncertainty estimates to make them
                            more reliable for the sellers to use.
                          </p>
                        </div>
                      </div>
                      <div className="bx--row">
                        <div
                          className="bx--col-lg-4"
                          style={{ textAlign: "right" }}
                        >
                          {/* <img
                            src="/imgs/Ella.png"
                            style={{ width: 80 }}
                            alt="Ella"
                          /> */}
                          <BusinessOwner width="100px" height="100px" />
                        </div>
                        <div className="bx--col-lg-12">
                          <h4>Ella—a house seller</h4>
                          <p>
                            Ella oversees the selling of houses in a
                            neighborhood. A critical task of her job is to set
                            the right sales prices for houses that she lists on
                            a real estate marketplace website. She would like to
                            use the ML system to get recommended prices.
                          </p>
                        </div>
                      </div>

                      <p>
                        Next, let’s choose a task that either John or Ella is
                        going to perform, and explore what UQ360 offers to help
                        them complete the tasks.
                      </p>

                      <p>
                        <Button onClick={() => history.push("/demo/1")}>
                          Next
                        </Button>
                      </p>
                    </div>
                  </Route>
                  <Route exact path="/demo/1">
                    <div className="bx--col-lg-16">
                      <p>
                        Select a task to proceed. The steps suggest a typical
                        workflow but you may choose to start from any task.
                      </p>
                      <Tile task="choose"></Tile>
                      <Tile task="calibration"></Tile>
                      <Tile task="improve"></Tile>
                      <Tile task="decision"></Tile>
                    </div>
                  </Route>
                  <Route exact path={"/demo/2/:task"} component={Task} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
