import React from "react";
import { SimpleContentPage } from "../../components";

const tutorials = [
  {
    name: "Calibrated Housing Price Prediction",
    link: "https://github.com/IBM/UQ360/blob/main/examples/tutorials/tutorial-synthetic_housing_dataset.ipynb",
    desc: "Illustrates using the housing price prediction task the consumption of uncertainties by two personas: the decision maker and the model developer. This tutorial covers the basic concepts of uncertainty quantification and evaluation in a simple regression setting.",
  },
  {
    name: "Selective Classification on Adult Income Dataset",
    link: "https://github.com/IBM/UQ360/blob/main/examples/tutorials/tutorial-adult_income_dataset.ipynb",
    desc: "Shows the usage of uncertainty information to help end users and developers compare the model's performance with male v.s. female customers. This tutorial covers the basic concepts of uncertainty quantification and evaluation in the selective classification setting.",
  },
];

const examples = [
  {
    name: "Auxiliary Interval Predictors",
    link: "https://github.com/IBM/UQ360/blob/main/examples/auxiliary_interval_predictor/demo_auxiliary_interval_predictor.ipynb",
    desc: "Demonstrates the use of Auxiliary Interval Predictors for calibration aware regression.",
  },
  {
    name: "Blackbox MetaModel",
    link: "https://github.com/IBM/UQ360/blob/main/examples/blackbox_metamodel/demo_blackbox_metamodel.ipynb",
    desc: "Demonstrates the use of Blackbox MetaModel for post-hoc extraction of uncertainty from pre-trained models.",
  },
  {
    name: "Blackbox MetaModel",
    link: "https://github.com/IBM/UQ360/blob/main/examples/blackbox_metamodel/demo_blackbox_metamodel.ipynb",
    desc: "Demonstrates the use of Blackbox MetaModel for post-hoc extraction of uncertainty from pre-trained models.",
  },
  {
    name: "BNN Classification",
    link: "https://github.com/IBM/UQ360/blob/main/examples/bnn_classification/demo_bnn_classification.ipynb",
    desc: "Demonstrates the use of BNNs and uncertainty decomposition for selective classification on UCI Adult Income Dataset.",
  },
  {
    name: "Gaussian Process Regression",
    link: "https://github.com/IBM/UQ360/blob/main/examples/gp_regression/demo_gp_regression_meps_dataset.ipynb",
    desc: "Demonstrates the use of Gaussian Process regression on MEPS dataset for the healthcare utilization prediction task.",
  },
  {
    name: "Heteroscedastic Regression",
    link: "https://github.com/IBM/UQ360/blob/main/examples/heteroscedastic_regression/demo_heteroscedastic_regression.ipynb",
    desc: "Demonstrates the use of regression with heteroscedastic noise.",
  },
  {
    name: "Infinitesimal Jackknife",
    link: "https://github.com/IBM/UQ360/blob/main/examples/infinitesimal_jackknife/demo_infinitesimal_jackknife.ipynb",
    desc: "Illustrates the infinitesimal jackknife (IJ) for logistic regression.",
  },
  {
    name: "Quantile Regression",
    link: "https://github.com/IBM/UQ360/blob/main/examples/quantile_regression/demo_quantile_regression.ipynb",
    desc: "Demonstrates the use of regression with quantile loss.",
  },
  {
    name: "Structured Infinitesimal Jackknife",
    link: "https://github.com/IBM/UQ360/blob/main/examples/infinitesimal_jackknife/demo_structured_infinitesimal_jackknife.ipynb",
    desc: "Demonstrates structured infinitesimal jackknife (IJ) approximations for cases where data are not independent across folds of the jackknife.",
  },
  {
    name: "Uncertainty Characteristics Curve",
    link: "https://github.com/IBM/UQ360/blob/main/examples/ucc_metric/demo_ucc_class.ipynb",
    desc: "Demonstrates the basic usage of the Uncertainty Characteristics Curve (UCC).",
  },
];

export const Tutorials = () => (
  <SimpleContentPage key="Tutorials" title="Tutorials">
    <h3>Tutorials</h3>
    <p>
      <ul>
        {tutorials.map((t) => (
          <li className="research-paper" key={t.name}>
            <a href={t.link} target="_blank" rel="noopener noreferrer">
              <strong>{t.name}</strong>
            </a>: {" "}
            {t.desc}
          </li>
        ))}
      </ul>
    </p>
    <h3>Examples</h3>
    <p>
      <ul>
        {examples.map((t) => (
          <li className="research-paper" key={t.name}>
            <a href={t.link} target="_blank" rel="noopener noreferrer">
              <strong>{t.name}</strong>
            </a>: {" "}
            {t.desc}
          </li>
        ))}
      </ul>
    </p>
  </SimpleContentPage>
);
