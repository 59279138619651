import React from "react";

type Paper = {
    title: string,
    url: string,
    authors: string,
    publication?: string,
    date: string,
    other_appearances?: string,
    other_url?: string,
    other_date?: string,
    our_paper?: boolean,
}

export const researchPapers : Paper[] = [
    {
        title: "Uncertainty Quantification 360: A Holistic Toolkit for Quantifying and Communicating the Uncertainty of AI",
        url: "https://arxiv.org/abs/2106.01410",
        authors: "Soumya Ghosh, Q. Vera Liao, Karthikeyan Natesan Ramamurthy, Jiri Navratil, Prasanna Sattigeri, Kush R. Varshney, Yunfeng Zhang",
        publication: "arXiv",
        date: "2021",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Uncertainty Characteristics Curves: A Systematic Assessment of Prediction Intervals",
        url: "https://arxiv.org/abs/2106.00858",
        authors: "Jiri Navratil, Benjamin Elder, Matthew Arnold, Soumya Ghosh, Prasanna Sattigeri",
        publication: "arXiv",
        date: "2021",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Uncertainty as a Form of Transparency: Measuring, Communicating, and Using Uncertainty",
        url: "https://arxiv.org/abs/2011.07586",
        authors: "Umang Bhatt, Javier Antorán, Yunfeng Zhang, Q. Vera Liao, Prasanna Sattigeri, Riccardo Fogliato, Gabrielle Gauthier Melançon, Ranganath Krishnan, Jason Stanley, Omesh Tickoo, Lama Nachman, Rumi Chunara, Madhulika Srikumar, Adrian Weller, Alice Xiang",
        publication: "AIES",
        date: "2021",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Learning Prediction Intervals for Model Performance",
        url: "https://arxiv.org/abs/2012.08625",
        authors: "Benjamin Elder, Matthew Arnold, Anupama Murthi, Jiri Navratil",
        publication: "AAAI",
        date: "2021",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Uncertainty Prediction for Deep Sequential Regression Using Meta Models",
        url: "https://arxiv.org/abs/2007.01350",
        authors: "Jiri Navratil, Matthew Arnold, Benjamin Elder",
        publication: "arXiv",
        date: "2020",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Approximate Cross-Validation for Structured Models",
        url: "https://papers.nips.cc/paper/2020/hash/636efd4f9aeb5781e9ea815cdd633e52-Abstract.html",
        authors: "Soumya Ghosh, Will Stephenson, Tin D. Nguyen, Sameer Deshpande, Tamara Broderick",
        publication: "NeurIPS",
        date: "2020",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Building Calibrated Deep Models via Uncertainty Matching with Auxiliary Interval Predictors",
        url: "https://ojs.aaai.org//index.php/AAAI/article/view/6062",
        authors: "Jayaraman J. Thiagarajan, Bindya Venkatesh, Prasanna Sattigeri, Peer-Timo Bremer",
        publication: "AAAI",
        date: "2020",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Active learning of deep surrogates for PDEs: application to metasurface design",
        url: "https://www.nature.com/articles/s41524-020-00431-2",
        authors: "Raphaël Pestourie, Youssef Mroueh, Thanh V. Nguyen, Payel Das & Steven G. Johnson",
        publication: "npj Computational Materials",
        date: "2020",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Effect of confidence and explanation on accuracy and trust calibration in AI-assisted decision making",
        url: "https://dl.acm.org/doi/10.1145/3351095.3372852",
        authors: "Yunfeng Zhang, Q. Vera Liao, Rachel K. E. Bellamy",
        publication: "FAccT",
        date: "2020",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Improving Reliability of Clinical Models using Prediction Calibration",
        url: "https://www.osti.gov/servlets/purl/1671186",
        authors: "J. J. Thiagarajan, B. Venkatesh, D. Rajan, P. Sattigeri",
        publication: "MICCAI",
        date: "2020",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Model Selection in Bayesian Neural Networks via Horseshoe Priors",
        url: "https://www.jmlr.org/papers/v20/19-236.html",
        authors: "Soumya Ghosh, Jiayu Yao, Finale Doshi-Velez",
        publication: "JMLR",
        date: "2019",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Confidence Scoring Using Whitebox Meta-models with Linear Classifier Probes",
        url: "http://proceedings.mlr.press/v89/chen19c.html",
        authors: "Tongfei Chen, Jiri Navratil, Vijay Iyengar, Karthikeyan Shanmugam",
        publication: "PMLR",
        date: "2019",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    }
];

const renderPapers = (papers: Paper[]) => (
    <ul>
     {
     papers.map( (paper) => (
        <li className="research-paper" key={paper.title}>
            <a href={paper.url} target="_blank" rel="noopener noreferrer"><strong>{paper.title}</strong></a>
            , {paper.authors}
            { paper.publication && <>, {paper.publication}</>}
            { paper.date && <>, {paper.date}</>}
            { paper.other_appearances && <>, <a href={paper.other_url} target="_blank" rel="noopener noreferrer">{paper.other_appearances}</a></>}
            { paper.other_date && <>, {paper.other_date}</>}
        </li>
    ))}
    </ul>
)

export const renderAllResearchPapers = () => (
    renderPapers(researchPapers)
)

export const renderOwnResearchPapers = () => (
    renderPapers(researchPapers.filter( rp => rp.our_paper ))
)