import React from "react";
import { TooltipDefinition } from "carbon-components-react";
import { glossary } from "../../assets/glossary";

function titleCase(str) {
  str = str.split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

export default ({ children }: { children: string }) => {
  const key = titleCase(children);
  if (Object.keys(glossary).includes(key)) {
    return (
      <TooltipDefinition
        triggerClassName="overview-term-definition"
        align="center"
        tooltipText={glossary[key]}
      >
        {children}
      </TooltipDefinition>
    );
  } else {
    return (
      <TooltipDefinition
        triggerClassName="overview-term-definition"
        align="center"
        tooltipText="missing definition"
      >
        {children}?
      </TooltipDefinition>
    )
  }
};
