import React from 'react';
import { SimpleContentPage } from '../../components';

import './OurPapers.css';
import { renderOwnResearchPapers } from '../../assets/papers';

export const Papers = () => (
    <SimpleContentPage key="Papers" title="Our Papers">
        <h3>IBM Research's Papers on Uncertainty Quantification</h3>
        <div className="div-as-paragraph our-papers-container">{ renderOwnResearchPapers() }</div>
    </SimpleContentPage>
)