import React from "react";
import { ReactComponent as DataScientist } from "../../assets/icons/DataScientist.svg";
import { Link, useHistory } from "react-router-dom";
import { Button } from "carbon-components-react";
import Term from "../Term/Term";

export function Choose() {
  const history = useHistory();
  return (
    <>
      <div className="bx--row">
        <div className="bx--col-lg-2">
          <DataScientist width="90px" height="90px" />
        </div>
        <div className="bx--col-lg-14">
          <h3>Choose a UQ method</h3>
          <p>
            John, the data scientist wants to know
            <br />
            <span className="question">
              Which UQ method is most suitable for my task?
            </span>
          </p>
        </div>
      </div>
      <p>
        The choice of UQ method depends on several factors. You can read a
        detailed{" "}
        <Link to="/resources/guidance">Guidance on choosing UQ algorithms</Link>
        .
      </p>
      <p>
        Following the guidance below, John recognizes that:
        <ul>
          <li>
            He would like to train an intrinsically uncertainty-capable model,
            since he has not built the model yet
          </li>
          <li>
            He would like to capture model uncertainty so the UQ estimates give
            him a more complete picture
          </li>
          <li>He has a relatively small dataset</li>
        </ul>
      </p>
      <p>
        <img
          src="/imgs/taxonomy_gray.png"
          alt="taxonomy"
          style={{ width: "800px" }}
        ></img>
      </p>
      <p>
        Based on these consideration, John chooses to implement{" "}
        <a
          href="https://uq360.readthedocs.io/en/latest/intrinsic.html#homoscedastic-gaussian-process-regression"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gaussian processes
        </a>{" "}
        to obtain uncertainty estimates expressed as{" "}
        <Term>prediction interval</Term>s with 95% confidence, that is, to tell
        where the predicted price can fall with 95% probability.
      </p>

      <p>
        Next task for John is to evaluate, and if needed, improve the UQ from
        the chosen method. Or you can go back to check out a different task.
      </p>

      <Button onClick={() => history.push("/demo/2/calibration")}>
        Next: Evaluate and Improve UQ Quality
      </Button>
    </>
  );
}
